import Vue from 'vue'
import VueRouter from 'vue-router'
import students from './students'
import whatsapp from './whatsapp'
import teachers from './teachers'
import calls from './calls'
import results from './results'
import committees from './committees'
import lessons from './lessons'
import xteacher from './xteacher'
import mentors from './mentors'
import table from './table'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/offer',
      name: 'Offer',
      component: () => import('@/views/pages/Offer.vue')
    },
    {
      path: '/ticket/:id',
      name: 'ticket',
      component: () => import('@/views/pages/ticket.vue')
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import('@/views/pages/templates.vue')
    },
    //
    {
      path: '/parent-student-full-report/:id',
      name: 'parent-student-full-report',
      component: () => import('@/views/public/parent-full-report.vue'),
      meta: {
        layout: 'full',
      }
    },
    {
      path: '/weekplan/:i/student/:id',
      name: 'weekplan',
      component: () => import('@/views/public/weekplan.vue'),
      meta: {
        layout: 'full',
      }
    },
    {
      path: '/weekplan/:i/classname/:id',
      name: 'weekplanclassname',
      component: () => import('@/views/public/weekplan-classname.vue'),
      meta: {
        layout: 'full',
      }
    },
    //
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/app-settings',
      name: 'app-settings',
      component: () => import('@/views/settings/app-settings.vue')
    },
    {
      path: '/extension',
      name: 'extension',
      component: () => import('@/views/extension.vue')
    },
    {
      path: '/installed',
      name: 'installed',
      component: () => import('@/views/installed.vue')
    },
    {
      path: '/expired',
      name: 'expired',
      component: () => import('@/views/subscribe/expired.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/subscribe/subscribe.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/update-count',
      name: 'update-count',
      component: () => import('@/views/subscribe/update-count.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/pages/contact.vue')
    },
    {
      path: '/subscribes',
      name: 'subscribes',
      component: () => import('@/views/subscribe/subscribes.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/index.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/autologin/:i',
      name: 'autologin',
      component: () => import('@/views/pages/autologin.vue')
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/views/pages/activate.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/pages/reset.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/pages/register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/logout.vue')
    },
    {
      path: '/sms',
      name: 'sms',
      component: () => import('@/views/settings/sms.vue')
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/pages/feedback.vue')
    },
    {
      path: '/_masrufi',
      name: '_masrufi',
      component: () => import('@/views/masrufi.vue')
    },
    ...students,
    ...whatsapp,
    ...teachers,
    ...calls,
    ...results,
    ...committees,
    ...lessons,
    ...xteacher,
    ...mentors,
    ...table,
    {
      path: '/s/:i',
      name: 's',
      component: () => import('@/views/ShortLink.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/teacher-ask/:i',
      name: 'teacher-ask',
      component: () => import('@/views/public/teacher-ask.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/mail/:i/:s',
      name: 'mail',
      component: () => import('@/views/public/mail.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/result/:i/:c',
      name: 'result',
      component: () => import('@/views/results/result.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/system_stopped',
      name: 'system_stopped',
      component: () => import('@/views/error/system_stopped.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/NotPermitted',
      name: 'NotPermitted',
      component: () => import('@/views/error/NotPermitted.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
